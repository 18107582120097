<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Добавить бренд</h4>
          </div>
        </md-card-header>
        <ValidationObserver ref="addBrandForm" :slim="true">
          <md-card-content>
            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Название</label>
                <md-input v-model.trim="brand.name" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Лого</label>
                <md-input v-model.trim="brand.logo" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Статус</label>
                <md-select v-model="brand.is_active" @focus="reset">
                  <md-option :value="1" placeholder="Статус">Активен</md-option>
                  <md-option :value="0">Не активен</md-option>
                </md-select>
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Тип</label>
                <md-select v-model="brand.is_main" @focus="reset">
                  <md-option :value="1" placeholder="Тип">Основной</md-option>
                  <md-option :value="0">Не основной</md-option>
                </md-select>
              </md-field>
            </ValidationProvider>

            <div class="buttons-wrap">
              <md-button class="md-raised md-success" @click="addNewBrand">
                Сохранить
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

const initBrand = () => ({
  name: "",
  logo: "",
  is_active: null,
  is_main: null,
});

export default {
  data() {
    return {
      brand: initBrand(),
    };
  },

  methods: {
    ...mapActions("brands", ["createBrand"]),

    async addNewBrand() {
      const isValid = await this.$refs.addBrandForm.validate();
      if (!isValid) {
        return;
      }

      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.createBrand(this.brand);
      if (success) {
        this.brand = initBrand();
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-wrap {
  display: flex;
  justify-content: space-between;
}
</style>
